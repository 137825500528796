// Logic for accordion
const accordion = ( () => {
  const $accordionElem = $( '.calculator-accordion' );
  const $accordionHeaderLink = $accordionElem.find( '.js-accordion__entry-header-link' );

  const settings = {
    speed: parseInt( $accordionElem.attr( 'data-speed' ) ) || 400,
    individualOpenable: $accordionElem.attr( 'data-individual-openable' ) === 'true',
  };

  return {
    init: () => {
      $accordionHeaderLink.on( 'click', function ( e ) {
        e.preventDefault();
        accordion.toggle( $( this ) );
      } );

      if ( !settings.individualOpenable && $( '.calculator-accordion__entry.is-expanded' ).length > 1 ) {
        $( '.calculator-accordion__entry.is-expanded' ).removeClass( 'is-expanded' );
      }
    },
    toggle: ( self ) => {
      if ( !settings.individualOpenable &&
        self[0] !==
        self.closest( '.calculator-accordion' )
          .find( '.calculator-accordion__entry.is-expanded .calculator-accordion__entry-header-link' )[0] ) {
        self.closest( '.calculator-accordion' )
          .find( '.calculator-accordion__entry' )
          .removeClass( 'is-expanded' )
          .find( '.calculator-accordion__entry-body' )
          .slideUp();
      }

      self.closest( '.calculator-accordion__entry' )
        .toggleClass( 'is-expanded' )
        .attr( 'aria-expanded', ( i, attr ) => ( attr === 'true' ? 'false' : 'true' ) );

      self.parent().attr( 'aria-selected', ( i, attr ) => ( attr === 'true' ? 'false' : 'true' ) );

      self.parent()
        .next()
        .stop()
        .slideToggle( settings.speed )
        .attr( 'aria-hidden', ( i, attr ) => ( attr === 'true' ? 'false' : 'true' ) );
    },
  };
} )();

const initAccordion = () => {
  accordion.init();
};

$( document ).ready( () => { 
  initAccordion();
} );
