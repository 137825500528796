$( ( $ ) => {
  $( '.notify-bar .close-icon' ).on( 'click', () => {
    $( '.notify-bar' ).addClass( 'notify-bar-closed' );
    $( 'body' ).addClass( 'notify-bar-removed' );
    // By pass ajax call
    $( 'body' ).removeClass( 'notify-bar-enable' );
  } );
} );


