$( ( $ ) => {
  const appendTitles = () => {
    if ( $( '.bs-column--events-title' ).length ) {
      const parentRow = $( '.bs-column--events-title' ).parents( '.bs-row' );
      parentRow.each( ( index, element ) => {
        const titleColumn = $( element ).find( '.bs-column--events-title' );
        const postParent = $( element ).find( '.bs-posts__list' );
        const postParentPast = $( element ).find( '.bs-posts__normal-row' );
        if (
          postParent.length &&
          ! postParent.children( '.bs-column--events-title' ).length
        ) {
          postParent.prepend( titleColumn.clone() );
        }
        if (
          postParentPast.length &&
          ! postParentPast.children( '.bs-column--events-title' ).length
        ) {
          postParentPast.prepend( titleColumn.clone() );
        }
      } );
    }
  };
  const appendDateAndType = () => {
    const eventList = $( '.bs-section--events-upcoming-events .bs-row--resources-list .bs-posts__list'	);
    const pastEventList = 
    $( '.bs-section--events-past-events .bs-post-block--event-past-events .bs-posts__normal-row'	);
    const newsList = $( '.bs-section--common-post-list .bs-post-news .bs-posts__list'	);
    if ( eventList.length ) {
      eventList.find( '.bs-posts__column' ).each( ( index, element ) => {
        $( element ).find( '.bs-post__event-type, .bs-post__date' ).wrapAll( 
          '<div class=bs-post__type-date-wrapper></div>' );
      } );
    }
    if ( newsList.length ) {
      newsList.find( '.bs-posts__column' ).each( ( index, element ) => {
        $( element ).find( '.bs-post__news-type, .bs-post__date' ).wrapAll(
          '<div class=bs-post__type-date-wrapper></div>' );
      } );
    }
    if ( pastEventList.length ) {
      pastEventList.find( '.bs-post' ).each( ( index, element ) => {
        $( element ).find( '.bs-post-taxonomy_event-type, .bs-post__meta_event_start_date' ).wrapAll(
          '<div class=bs-post__type-date-wrapper></div>' );
      } );
    }
  };

  appendTitles();
  appendDateAndType();

  $( document ).on( 'facetwp-loaded', () => {
    appendTitles();
    appendDateAndType();
  } );

} );
