$( () => {
  window.addEventListener( 'load', () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams( queryString );
    if( !urlParams.has( 'p' ) ) return;
    const targetElement = document.querySelector( '.bs-section--why-join-devo-ats' );
    const targetComponent = document.querySelector( '.jv-careersite' );
    if ( targetComponent ) {
      targetElement.scrollIntoView( { behavior: 'smooth' } );
    }
  } );
} );