/* eslint-disable no-unused-vars */
require( 'slick-carousel-latest' );
$( ( $ ) => {
  window.onload = () => {
    const section = $( '.bs-section--home-testimonials-section' ),
      slider = $( '.bs-section--home-testimonials-section .bs-slider-content' ),
      counters = $( '.bs-div--testimonial-counters .bs-row' );
    slider.slick();
    $( counters ).each( ( index, element ) => {
      $( element ).attr( 'data-counter-index', index );
    } );
    if ( $( slider ).length ) {
      /* eslint-disable max-len */
      $( slider ).on( 'beforeChange' , function( event, slick, currentSlide, nextSlide ) {
        section.find( '.bs-div--testimonial-counters .bs-row' ).removeClass( 'bs-row--counter-active' );
        section.find( `.bs-div--testimonial-counters .bs-row[data-counter-index=${nextSlide}]` ).addClass( 'bs-row--counter-active' );
      } );
    }
  };
} );
/* eslint-enable no-unused-vars */
