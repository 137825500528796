$( ( $ ) => {
  const pricingHeader = $( '.bs-section--pricing-features-table .table-wrapper' );
  
  const observerOptions = {
    root: null,
    rootMargin: '0px 0px -55% 0px',
    threshold: 0
  };

  const setPricingHeaderSticky = entries => {
    entries.forEach( entry => {
      if ( entry.isIntersecting || entry.boundingClientRect.top < 0 ) {
        $( pricingHeader ).addClass( 'header-pinned' );
      } else {
        $( pricingHeader ).removeClass( 'header-pinned' );
      }
    } );
  };

  const observer = new IntersectionObserver( setPricingHeaderSticky, observerOptions );
  if ( pricingHeader.length ) {
    observer.observe( pricingHeader[0] );
  }

} );