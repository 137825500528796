( $ => {
  $.fn.parallaxMove = function( options ) {
    const settings = $.extend(
      {
        offset: 0,
        mobile: false,
        dir: 'top',
        headerFixed: false,
      },
      options
    );
    const currEl = this;
    const wH = $( window ).height();
    const wW = $( window ).width();
    const hH = $( 'header' ).height();
    const parent = currEl.parents( 'section' );
    const boundingBox = parent[0].getBoundingClientRect();
    const parentTop = boundingBox.top;
    const parentBottom = boundingBox.bottom;
    const speed = settings.headerFixed
      ? settings.offset / ( wH - hH )
      : settings.offset / wH;
    const inViewHeight = wH - parentTop;
    const scrollPortion =
      settings.dir === 'top'
        ? settings.offset - inViewHeight * speed
        : ( settings.offset - inViewHeight * speed ) * -1;
    const disableParallax = wW < 1200 && !settings.mobile ? true : false;

    if (
      parentTop <= parseInt( wH + 100 ) &&
      parentBottom >= 0 &&
      parentTop >= 100 &&
      !disableParallax
    ) {
      currEl.css( {
        transform: 'translate3d(0,' + scrollPortion + 'px,0)',
      } );
    } else if ( disableParallax ) {
      currEl[0].style.removeProperty( 'transform', '-webkit-transform' );
    } else {
      currEl.css( {
        transform: '',
      } );
    }
    currEl.addClass( 'parallax-item' );
  };
} )( jQuery );

$( window ).on( 'load scroll resize', () => {
  if ( $( '.r-imgtxt-l2--dvo3 .bs-div--r-imgtxt-media-wrapper' ).length ) {
    $( '.r-imgtxt-l2--dvo3 .bs-div--r-imgtxt-media-wrapper .media-elements' )
      .eq( 0 )
      .parallaxMove( {
        offset: -200, 
        headerFixed: true,
      } );
    $( '.r-imgtxt-l2--dvo3 .bs-div--r-imgtxt-media-wrapper .media-elements' )
      .eq( 1 )
      .parallaxMove( {
        offset: 200,
        headerFixed: true,
      } );
    $( '.bs-div--r-imgtxt-media-wrapper .media-elements' )
      .eq( 2 )
      .parallaxMove( {
        offset: 100,
        headerFixed: true,
      } );
  }

  if ( $( '.bs-section--image-content .bs-div--about-image-content-wrapper' ).length ) {
    $( '.bs-section--image-content .bs-div--about-image-content-wrapper .media-elements' )
      .eq( 0 )
      .parallaxMove( {
        offset: -100, 
        headerFixed: true,
      } );
    $( '.bs-section--image-content .bs-div--about-image-content-wrapper .media-elements' )
      .eq( 1 )
      .parallaxMove( {
        offset: 200,
        headerFixed: true,
      } );
  }
} );
