$( ( $ ) => {
  $( '.bs-pro-button--current-openings' ).on( 'click', ( e ) => {
    e.preventDefault();
    const currentOpeningsEl = $( '.bs-section--why-join-devo-ats' );
    if ( currentOpeningsEl.length !== 0 ) {
      const hH = $( 'header' ).height(),
        elTop = currentOpeningsEl.offset().top;
      $( 'html, body' ).animate(
        {
          scrollTop: elTop - ( hH + 40 ),
        }
      );
    }
  } );
} );
