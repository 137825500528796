$( ( $ ) => {
  const parallaxContainer = $( '.bs-section--parallax-this-section' );
  const afterSection = $( '.bs-section--customers-testimonial' );
	
  if ( parallaxContainer.length ) {
    let initialGap = parallaxContainer.outerHeight();
    const parallaxElement = parallaxContainer[0];
    const observerOptions = {
      root: null,
      rootMargin: `0px 0px -${initialGap}px 0px`,
      threshold: 0
    };
		
    const parallaxCallback = entries => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          window.addEventListener( 'scroll', parallax );
        } else {
          window.removeEventListener( 'scroll', parallax );
        }
      } );
    };
		
    const parallax = () => {
      const scrollPosition = $( window ).scrollTop();
      const parallaxOffset = afterSection.offset().top;
      const windowHeight = window.innerHeight;    
      const yPos = ( scrollPosition + windowHeight - parallaxOffset ) * -0.26;
      $( parallaxElement ).css( 'height', initialGap + yPos );
    };
		
    const observer = new IntersectionObserver( parallaxCallback, observerOptions );
    observer.observe( parallaxElement );
  }
} );
