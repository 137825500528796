$( ( $ ) => {
  const partnersContentWrapper = () => {
    const postList = $( '.bs-section--partners-directory .bs-post__details'	);
    if ( postList.length ) {
      postList.each( ( i, item ) => {
        const wrapperContainer = $( item ).find( '.bs-post__text-content-wrapper' ), 
          title = $( item ).find( '.bs-post__title' ), 
          description = $( item ).find( '.bs-post__description' );
        if( wrapperContainer.length < 1 ) {
          const div = document.createElement( 'div' );
          $( div ).attr( 'class', 'bs-post__text-content-wrapper' );
          $( item ).append( div );
        }
        if( title && description ) {
          $( item ).find( '.bs-post__text-content-wrapper' ).append( title, description );
        }
      } );
    }
  };

  partnersContentWrapper();

  $( document ).on( 'facetwp-loaded', () => {
    partnersContentWrapper();
  } );

} );