$( ( $ ) => {
  const hoverElement = $( '.login-icon-class' );
  const searchElement = $( '.menu-search-icon' );

  hoverElement.on( 'mouseover', () => {
    if ( !hoverElement.hasClass( 'show-logins' ) ) {
      hoverElement.addClass( 'show-logins' );
    }
  } );

  hoverElement.on( 'mouseout', () => {
    if ( hoverElement.hasClass( 'show-logins' ) ) {
      hoverElement.removeClass( 'show-logins' );
    }
  } );

  hoverElement.on( 'click', () => {
    if ( searchElement.hasClass( 'open-search' ) ) {
      searchElement.removeClass( 'open-search' );
    }
		
  } );
} );
