$( $ => {
  const slickSliders = $( '[data-slick]' );
  slickSliders.each( ( index, slickSlider ) => {
    const thisSlick = $( slickSlider ),
      currSlickData = thisSlick.data( 'slick' ),
      currSlides = thisSlick.find( '.slick-slide-wrapper' ),
      currSlidesLength = currSlides.length;
    if ( currSlickData.responsive ) {
      Object.values( currSlickData.responsive ).forEach( point => {
        if ( currSlidesLength <= point.settings.slidesToShow ) {
          if ( point.breakpoint === 9999 ) {
            thisSlick.parents( 'section' ).addClass( 'no-arrows-xl' );
          } else if ( point.breakpoint === 1200 ) {
            thisSlick.parents( 'section' ).addClass( 'no-arrows-lg' );
          } else if ( point.breakpoint === 992 ) {
            thisSlick.parents( 'section' ).addClass( 'no-arrows-md' );
          } else if ( point.breakpoint === 576 ) {
            thisSlick.parents( 'section' ).addClass( 'no-arrows-sm' );
          }
        }
      } );
    }
  } );
} );
