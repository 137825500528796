/* global debounce */
let calculatorSection = $( '#calculator-wrapper' );
let jqxhr = {abort: () => {}};

let rangeInputs = calculatorSection.find( 'input.range-input' );

const getCalculatorPostData = ( withEstimateValue = false ) => {
  let postData = {};
  rangeInputs.each( ( i, e ) => {
    let input = $( e );
    let formFieldName = input.data( 'form-field' );
    if ( formFieldName ) {
      postData[formFieldName] = parseInt( input.val() );
    }
  } );
  if( withEstimateValue ) {
    let estimatedDailyField = calculatorSection.find( '#daily_value' );
    postData[estimatedDailyField.attr( 'data-form-field' )] = estimatedDailyField.attr( 'data-value' );
  }
  return postData;
};

const formatResultValues = ( numberString ) => {
  const number = parseFloat( numberString );
  if ( Number.isInteger( number ) ) {
    return String( number );
  } else {
    return number.toFixed( 2 );
  }
};

const displayCalculatorResults = ( response ) => {
  const data = response.data;
  const types = ['daily', 'weekly', 'monthly', 'yearly'];
  types.forEach( ( item ) => {
    const valueSpan = $( '#estimated-volume #' + item + '_value' );
    if( item==='daily' ) {
      valueSpan.attr( 'data-value', data['total_without_format'] );
    }
    valueSpan.text( data[item + '_value'] );
    $( '#estimated-volume #' + item + '_unit' ).text( data[item + '_unit'] );
  } );
  data.input_data.forEach( ( item ) => {
    $( '.calculator-accordion__entry-eps .eps-value[data-form-field="' + item.form_field + '"]' )
      .val( formatResultValues( item.eps_value ) );
    $( '.calculator-accordion__entry-day .gb-value[data-form-field="' + item.form_field + '"]' )
      .val( formatResultValues( item.gb_value ) );
  } );

  const innerInputFields = [
    {totalFieldClass: '.category-qty-total', inputFieldClass: '.range-input', total: 0},
    {totalFieldClass: '.category-eps-total', inputFieldClass: '.eps-value', total: 0},
    {totalFieldClass: '.category-gb-total', inputFieldClass: '.gb-value', total: 0}
  ];
  innerInputFields.forEach( ( totalItem )=>{
    $( '.calculator-accordion__entry-title' ).each( ( i, element ) => {
      let total = 0;
      const titleElement = $( element );
      const parentAccordion = titleElement.closest( '.calculator-accordion__entry' );
      const qtyInputFields = parentAccordion.find( '.calculator-accordion__entry-body '+totalItem.inputFieldClass );
      qtyInputFields.each( ( i, j ) => {
        const value = parseFloat( $( j ).val() ) || 0;
        total += value;
      } );
      titleElement.find( totalItem.totalFieldClass ).text( formatResultValues( total ) );
    } );
  } );
};

const calculateTotal = () => {
  jqxhr.abort();
  jqxhr = $.ajax( {
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    data: {
      action: 'submitDataCalculatorValues',
      inputs: getCalculatorPostData(),
      nonce: calculatorSection.find( '#calculator-content' ).data( 'nonce' ),
    },
    beforeSend: function () {
      $( '#estimated-volume' ).addClass( 'loading' );
    },
    success: ( response ) => {
      displayCalculatorResults( response );
      $( '#estimated-volume' ).removeClass( 'loading' );
    },
  } );
};
rangeInputs.on( 'input', debounce( calculateTotal, 500 ) );

const handleCalculatorEvent = ( calculatorSection, event ) => {
  const allowedOrigins = ['https://forms.hsforms.com', window.location.origin];
  if ( event.origin && allowedOrigins.includes( event.origin ) ) {
    if ( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady' ) {
      calculatorSection.find( '.bs-pro-button__container' ).on( 'click', ()=>{
        const dataArray = getCalculatorPostData( true );
        for ( let key in dataArray ) {
          $( 'form input.hs-input[name="'+key+'"]' ).val( dataArray[key] ).change();
        }
      } );
    }
  }
};

const setupCalculatorEvent = ( calculatorSection ) => {
  if ( calculatorSection.length > 0 ) {
    window.addEventListener( 'message', event => {
      try {
        handleCalculatorEvent( calculatorSection, event );
      } catch ( e ) {
        console.error( e );
      }
    } );
  }
};

// generate pdf and send email
const sendEmailWithPDF = ( email ) => {
  const dataArray = getCalculatorPostData();
  jqxhr.abort();
  jqxhr = $.ajax( {
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    data: {
      action: 'sendEmailWithPDF',
      inputs: dataArray,
      email: email,
      nonce: calculatorSection.find( '#calculator-content' ).data( 'nonce' ),
    },
    success: ( ) => {
      window.location.reload();
    },
  } );
};

// trigger email send after hubspot form submit
const calculatorPdfEmailSentEvent = ( calculatorSection ) => {
  const formId = calculatorSection.find( '.email-result' ).data( 'dv-form-id' );
  window.addEventListener( 'message', event => {
    if( event.data.type === 'hsFormCallback'
      && event.data.eventName === 'onFormSubmitted'
      && event.data.id === formId
    ) {
      const { email } = event.data.data.submissionValues;
      sendEmailWithPDF( email );
    }
  } );
};

$( document ).ready( () => { 
  setupCalculatorEvent( calculatorSection );
  calculatorPdfEmailSentEvent( calculatorSection );
} );
