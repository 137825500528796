( ( $ ) => {
  const adjustCardTitleHeight = () => {
    const section = $( '.r-ctacol-l4--dvo1' );
    const $cardTitles = section.find( '.bs-blurb__title > *' );

    const setMaxHeight = () => {
      let maxHeight = 0;

      $cardTitles.height( 'auto' );

      $cardTitles.each( ( i, elem ) => {
        const $this = $( elem );
        const titleInnerHeight = $this.innerHeight();

        if ( titleInnerHeight > maxHeight ) {
          maxHeight = titleInnerHeight;
        }
      } );

      $cardTitles.css( { height: `${maxHeight}px` } );
    };

    setMaxHeight();
    $( window ).on( 'resize', setMaxHeight );
  };

  const mediaQuery = window.matchMedia( '(min-width: 992px)' );

  const handleResize = ( mediaQuery ) => {
    if ( mediaQuery.matches ) {
      adjustCardTitleHeight();
    }
  };

  mediaQuery.addListener( handleResize );
  handleResize( mediaQuery );
} )( jQuery );
