$( document ).ready( () => {
  window.addEventListener( 'message', event => {
    try {
      const allowedOrigins = ['https://forms.hsforms.com', window.location.origin];
      if ( event.origin && allowedOrigins.includes( event.origin ) ) {
        handleHubSpotFormEvent( event.data );
      } else {
        return false;
      }
    } catch ( e ) {
      console.error( e );
    }
  } );
} );

function handleHubSpotFormEvent( data ) {
  if ( data && data.type === 'hsFormCallback' ) {
    switch ( data.eventName ) {
    case 'onFormReady':
      initializeFormTracking();
      initializeSubmitButton();
      break;
    case 'onFormSubmitted':
      handleFormSubmitted();
      break;
    default:
      break;
    }
  }
}

function initializeFormTracking() {
  const $el = $( '.hs-input' );

  const initialFieldValues = new Map();

  $el.each( function () {
    const fieldName = $( this ).attr( 'name' );
    initialFieldValues.set( fieldName, '' );
  } );

  setInterval( () => {
    $el.each( function () {
      const fieldName = $( this ).attr( 'name' );
      const currentValue = $( this ).val();

      if ( currentValue !== initialFieldValues.get( fieldName ) ) {
        $( this ).parent().parent().addClass( 'active' );
        initialFieldValues.set( fieldName, currentValue );
      }
    } );
  }, 300 );

  $( document ).on( 'load change input focus', '.hs-input', ( e ) => {
    const $currentTarget = $( e.currentTarget );
    const isEmpty = !$currentTarget.val() || $currentTarget.val() === '';
    $currentTarget.parent().parent().toggleClass( 'active', !isEmpty );
  } ).on( 'focusout', '.hs-input', ( e ) => {
    const $currentTarget = $( e.currentTarget );
    const isEmpty = !$currentTarget.val();
    $currentTarget.parent().parent().toggleClass( 'active', !isEmpty );
  } );
}

function initializeSubmitButton() {
  const hsSubmit = document.querySelector( '.bs-div--form-wrapper .hs-submit' );
  if ( hsSubmit ) {
    const input = hsSubmit.querySelector( 'input[type="submit"]' );
    hsSubmit.addEventListener( 'click', () => {
      input.click();
    } );
  }
}

function handleFormSubmitted() {
  const gatedSubmittedFormWrapper = $( '#gated-submitted-form-wrapper' );
  if ( gatedSubmittedFormWrapper.length ) {
    const submitButton = $( '<input>' ).attr( { type: 'submit', value: 'Submit' } );
    gatedSubmittedFormWrapper.append( submitButton );
    const formUrl = gatedSubmittedFormWrapper.find( '[name="url"]' ).val();
    gatedSubmittedFormWrapper.find( 'input' ).wrapAll( '<form action="'+formUrl+'" method="post"></form>' );
    gatedSubmittedFormWrapper.find( '[type="submit"]' ).click();
  }
}
