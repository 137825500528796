const paginationURLCheck = () => {
  let regex = /\/page\/\d+\//;
  if ( regex.test( window.location.href ) ) {
    const pastEventListTop = $( '.bs-post-block--event-past-events' );
    if ( pastEventListTop.length ) {
      const speed = 500;
      const adminBarHeight =
        $( '#wpadminbar' ).length > 0 ? $( '#wpadminbar' ).height() : 0;
      const headerHeight =
        $( 'header' ).height() + adminBarHeight;
      $( 'html, body' ).animate(
        { scrollTop: $( pastEventListTop ).offset().top - headerHeight },
        speed
      );
    }
  }
};
$( () => {
  $( window ).on( 'load', () => {
    paginationURLCheck();
  } );
} );