/* eslint-disable no-unused-vars */
( ( $ ) => {
  /* eslint-enable no-unused-vars */
  const videoPlayMid = document.querySelectorAll(
    '.bs-section--home-platform video'
  );
  const option = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  };

  $( window ).on( 'load', () => {
    videoPlay( videoPlayMid, option );
  } );

  const videoPlay = ( videos, settings ) => {
    const videoObserver = new IntersectionObserver( ( entries ) => {
      entries.forEach( ( entry ) => {
        if ( entry.isIntersecting ) {
          entry.target.play();
        } else {
          entry.target.pause();
        }
      } );
    }, settings );
    if ( videos ) {
      videos.forEach( ( video ) => {
        videoObserver.observe( video );
      } );
    }
  };
} )( jQuery );
