( $ => {
  const $commonLogoSliders = $( '.bs-section--common-marquee-slider' );

  let maxWidth = 0;
	
  if ( $commonLogoSliders.length > 0 ) {

    const logoPlayHandler = ( index, slider, direction, speed ) => {
      let cloneUl = $( slider ).find( '.clients-list' );
      const wrapperWidth = cloneUl.width();
      const animationSpeed = speed;
      const animationDiretion = direction;
      const logoCount = cloneUl.children().length;
      let isMobile = false;
      let keyframes = '';

      //get max width of slider wrappers to maintain equal speed
      if( maxWidth <= wrapperWidth ){
        maxWidth = wrapperWidth;
      }
      const calculatedSpeed = ( maxWidth*animationSpeed )/ 1000 ;

      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test( navigator.userAgent ) ) {
        isMobile = true;
      }
         
      if( logoCount > 6 || isMobile ){

        $( slider ).children().append( cloneUl.clone() );
        $( slider ).find( 'ul' ).first().addClass( 'marquee' );
        $( slider ).find( 'ul' ).last().addClass( 'marqueeCloned' );

        if( animationDiretion == 'left' ) {
          //define direction using keyframes
          keyframes = `
            @keyframes marqueeKeyFrameLeft${index} {
              0% { left: 0; }
              100% { left: -${wrapperWidth}px; }
            }
            @keyframes marqueeKeyFrameLeftCloned${index} {
              0% { left: ${wrapperWidth}px; }
              100% { left: 0; }
            }
          `;
          // Apply the animation to the element using the CSS 'animation' property
          $( slider ).find( '.marquee' )
            .css( 'animation', `marqueeKeyFrameLeft${index} ${calculatedSpeed}ms linear infinite` );
          $( slider ).find( '.marqueeCloned' )
            .css( 'animation', `marqueeKeyFrameLeftCloned${index} ${calculatedSpeed}ms linear infinite` );

        } else {
          keyframes = `
          @keyframes marqueeKeyFrameRight${index} {
            0% { left: 0; }
            100% { left: ${wrapperWidth}px; }
          }
          @keyframes marqueeKeyFrameRightCloned${index} {
            0% { left: -${wrapperWidth}px; }
            100% { left: 0; }
          }
        `;
          // Apply the animation to the element using the CSS 'animation' property
          $( slider ).find( '.marquee' )
            .css( 'animation', `marqueeKeyFrameRight${index} ${calculatedSpeed}ms linear infinite` );
          $( slider ).find( '.marqueeCloned' )
            .css( 'animation', `marqueeKeyFrameRightCloned${index} ${calculatedSpeed}ms linear infinite` );
        }
  
        const styleSheet = $( '<style></style>' );
        styleSheet.text( keyframes );
        // Append the style sheet to the head of the document
        $( 'head' ).append( styleSheet );
      } else {
        $( cloneUl ).addClass( 'no-animation' );
      }

    };
    if ( $commonLogoSliders.length !== 0 ) {
      //select sliders in a page
      $commonLogoSliders.find( '.clients-slider-wrapper' ).map( ( i, slider ) => {
        $( window ).on( 'load' , () => {
          logoPlayHandler( i, slider, $( slider ).attr( 'data-direction' ), $( slider ).attr( 'data-speed' ), );
        } );
      } );

      $( '.bs-section--common-marquee-slider ul.clients-list li a' ).mouseenter( element => {
        $( element.target ).closest( '.clients-slider-wrapper' ).addClass( 'stop-hover' );
      } ).mouseleave( element => {
        $( element.target ).closest( '.clients-slider-wrapper' ).removeClass( 'stop-hover' );
      } );
    }
  }
} )( jQuery );
