$( ( $ ) => {
  const wrapper = () => {
    const postList = $( '.bs-section--post-category-date-wrapper-included .bs-post'	);
    if ( postList.length ) {
      postList.each( ( i, item ) => {
        const wrapperContainer = $( item ).find( '.bs-post__type-date-wrapper' ), 
          category = $( item ).find( '.bs-post__category' ), 
          date = $( item ).find( '.bs-post__date' );
        if( wrapperContainer.length < 1 ) {
          const div = document.createElement( 'div' );
          $( div ).attr( 'class', 'bs-post__type-date-wrapper' );
          $( item ).find( '.bs-post__details' ).prepend( div );
        }
        if( category && date ) {
          $( item ).find( '.bs-post__type-date-wrapper' ).append( category, date );
        }
      } );
    }
  };

  wrapper();

} );