/* eslint-disable space-in-parens */
import 'rangeslider.js/dist/rangeslider.js';

$(($) => {
  let loaded = true;
  let dataRange = 0;

  $('input[type=range]').rangeslider({
    polyfill: false,
    onInit: function () {
      if (loaded === true) {
        createFigure();
      }
      $(this).get(0).$element[0].setAttribute('data-range', dataRange);
      const val = $(this).get(0).value;
      const exactPosition = $(this).get(0).position;
      rangeChange(val, dataRange, exactPosition);
      loaded = false;
      dataRange++;
    },
    onSlide: function (position, value) {
      const range = $(this).get(0).$element[0];
      const changeRange = $(range).data('range');
      const exactPosition = $(this).get(0).position;
      rangeChange(value, changeRange, exactPosition);
    },
    onSlideEnd: function() {
      const range = $(this).get(0).$element[0];
      const rangeInput = $(range).closest('tr').find( '.range-slider' );
      rangeInput.trigger('input');
    }
  });

  function createFigure() {
    const elementBlock = '<figure></figure>';
    $('.calculator-accordion__entry-column > div').prepend(elementBlock);
  }

  function rangeChange(val, dataRange, exactPosition) {
    let sliderValue = val;
    const selector = $('.js-range-slider[data-range="' + dataRange + '"]');

    selector.parents('.calculator-accordion__entry-column').next().find('.range-slider').val(sliderValue);
    selector.prev('figure').text(sliderValue);
    selector.prev('figure').css('left', exactPosition);
  }

  $('.range-slider').on('input', function () {
    let value = $(this).val();
    const rangeSlider = $(this).parent().prev().find('.js-range-slider');
    const rangeMaxValue = rangeSlider.attr('max');
    value = (value == '') ? 0 : value;
    if (parseInt(value)<=rangeMaxValue){
      rangeSlider.val(value).change();
    } else {
      rangeSlider.val(rangeMaxValue).change();
      $(this).val(value);
    }
  });

} );