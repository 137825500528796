/* global debounce */
import { LottieAnimator } from '~bergBlockPath/lottie-animator/js/core-event-handler';

const bottomRootMargin = $( window ).height() > $( window ).width() ? '-10%' : '-20%';
$.fn.lottieSequence = function ( options ) {
  const settings = $.extend(
    {
      sequenceInterval: 0,
      circular: false,
      activeOnSlide: false,
    },
    options
  );

  const lottieQueue = [];
  const mediaQuery = window.matchMedia( '(max-width: 992px)' );

  const observerOptions = {
    root: null,
    rootMargin: '0px 0px ' + bottomRootMargin + ' 0px',
  };

  const addLottieToQueue = lottie => {
    let no = $( lottie ).data( 'lottie-sequence' );
    if ( no === undefined ) {
      const animator = new LottieAnimator( lottie );
      animator.init();
      no = lottieQueue.length;
      $( lottie ).attr( 'data-lottie-sequence', no );
      lottieQueue.push( animator );
    }
    return lottieQueue[no];
  };

  const initiateLotties = lotties => {
    lotties.each( ( i, lottie ) => addLottieToQueue( lottie ) );
  };

  const animateLotties = lotties => {
    const animatorChain = [];
    const animateLottie = chainObject => {
      if ( !chainObject ) {
        return;
      }
      const lottieAnimation = chainObject.animator.lottieAnimation;

      if ( settings.activeOnSlide && mediaQuery.matches ) {
        const sliderElement = $( settings.onSlideSelector );

        sliderElement.not( '.slick-initialized' ).slick();
        sliderElement.on( 'afterChange', ( event, slick, currentSlide ) => {

          const activeItem = $( slick.$slides[currentSlide] );
          const activeLotties = activeItem.find( '.bs-lottie-animator' );
          const slides = sliderElement.find( '.slick-slide' );
          const inactiveLotties = slides.find( '.bs-lottie-animator' );


          inactiveLotties.each( function() {
            const animator = addLottieToQueue( this );
            animator.lottieAnimation.goToAndStop( 0, true );
            animator.lottieAnimation.stop();
          } );

          activeLotties.each( function() {
            const animator = addLottieToQueue( this );
            animator.lottieAnimation.goToAndPlay( 0, true );
            animator.lottieAnimation.play();
          } );
        } );
        // Initialize cloned lotties once

        const clonedLotties = $( '.slick-cloned' ).find( '.bs-lottie-animator' );

        clonedLotties.each( ( index, element ) => {

          if ( !element.hasAttribute( 'data-initialized' ) ) {
            element.setAttribute( 'data-initialized', 'true' );
            const lottieAnimator = new LottieAnimator( element );
            lottieAnimator.init();
            lottieAnimator.lottieAnimation.goToAndPlay( 0, true );
            lottieAnimator.lottieAnimation.stop();
          }
        } );

      } else {
        lottieAnimation.goToAndStop( 0, true );
        lottieAnimation.play();
        lottieAnimation.addEventListener(
          'complete',
          ( () => {
            const nextAnimator = debounce( () => {
              animateLottie( chainObject.next );
            }, settings.sequenceInterval );
            lottieAnimation.removeEventListener( 'complete' );
            return nextAnimator;
          } )()
        );
      }
    };
    lotties.each( ( i, lottie ) => {
      const chainObject = {
        animator: addLottieToQueue( lottie ),
      };
      if ( i > 0 ) {
        animatorChain[i - 1].next = chainObject;
      }
      animatorChain.push( chainObject );
    } );

    if ( animatorChain.length === 0 ) return;
    if ( settings.circular ) {
      animatorChain[animatorChain.length - 1].next = animatorChain[0];
    }
    animateLottie( animatorChain[0] );
  };

  $( this ).each( ( i, element ) => {
    const $element = $( element );
    const lotties = $element.find( '.bs-lottie-animator' );
    initiateLotties( lotties );

    const observer = new IntersectionObserver( entries => {
      if ( entries[0].isIntersecting ) {
        animateLotties( lotties );
        observer.disconnect();
      }
    }, observerOptions );

    observer.observe( element );
  } );
};

$( '.bs-div--brand-pillars' ).lottieSequence( {
  sequenceInterval: 900,
  circular: true,
} );

$( '.bs-div--blog-inner-cards' ).lottieSequence( {
  sequenceInterval: 900,
  circular: true,
} );

$( '.bs-div--blog-inner-counters' ).lottieSequence( {
  sequenceInterval: 900,
  circular: true,
} );

$( '.r-blbstk-l4--dvo1' ).lottieSequence( {
  sequenceInterval: 900,
  circular: true,
} );

$( '.r-imgcnt-l2--dvo1' ).lottieSequence( {
  sequenceInterval: 900,
  circular: true,
} );

$( '.r-crdstk-l4--dvo1' ).lottieSequence( {
  sequenceInterval: 900,
  circular: true,
} );

$( '.bs-section--customer-counter-section' ).lottieSequence( {
  sequenceInterval: 900,
  activeOnSlide: true,
  onSlideSelector: '.bs-section--customer-counter-section .slick-slider',
  circular: true,
} );

$( '.bs-section--platform-overview-card-slider' ).lottieSequence( {
  sequenceInterval: 900,
  activeOnSlide: true,
  onSlideSelector: '.bs-section--platform-overview-card-slider .slick-slider',
  circular: true,
} );

$( '.bs-section--platform-overview-adressing-weaknesses' ).lottieSequence( {
  sequenceInterval: 900,
  activeOnSlide: true,
  onSlideSelector: '.bs-section--platform-overview-adressing-weaknesses .slick-slider',
  circular: true,
} );