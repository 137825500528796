import { CountUp } from 'countup.js';

$( ( $ ) => {
  const sliderTabs = $( '.bs-tab-slider--testimonials .bs-slider-tabs h3' );
  if ( sliderTabs ) {
    sliderTabs.each( ( i, e ) => {
      $( e ).on( 'click', () => {
        setTimeout( restartCount, 200 );
      } );
    } );
  }
  //Counter restart
  const restartCount = () => {
    const counterElement = $(
      '.bs-tab-slider--testimonials .bs-slider-content .slick-slide.slick-current [data-counterup]'
    );

    if ( counterElement ) {
      counterElement.each( ( i, item ) => {
        const dataOptions = $( item ).data( 'options' ),
          countUp = new CountUp(
            item,
            dataOptions.endVal,
            dataOptions
          );

        if ( !countUp.error ) {
          countUp.start();
        }
      } );
    }
  };
} );

