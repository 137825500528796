/* eslint-disable no-undef, no-unused-vars */
$( () => {
  const isHiddenOnSmallScreens = ( index, activeIndex, lastIndex ) => {
    return !(
      index === 0 ||
      index === 1 ||
      index === lastIndex - 1 ||
      index === lastIndex ||
      index === activeIndex - 1 ||
      index === activeIndex ||
      index === activeIndex + 1
    );
  };

  const addClassesToElement = ( element, classes ) => {
    element.addClass( classes );
  };

  const insertDotsContentIfNeeded = ( hiddenContent, dotsContent ) => {
    if ( hiddenContent.length > 0 ) {
      hiddenContent.first().before( dotsContent );
      hiddenContent.remove();
    }
  };

  const mobilePaginationClassAdding = () => {
    const facetPagination = $( '.facetwp-facet-pagination' );
    if ( facetPagination.length > 0 && $( window ).width() < 576 ) {
      const paginationElements = facetPagination.find( '.facetwp-page' );
      const dotsContent = '<a class="facetwp-page dots">…</a>';
      const activeIndex = facetPagination.find( '.active' ).index();
      const lastIndex = paginationElements.length - 1;

      paginationElements.each( ( index, element ) => {
        const $element = $( element );

        if ( isHiddenOnSmallScreens( index, activeIndex, lastIndex ) ) {
          addClassesToElement( $element, 'hide-mobile' );
        }

        if ( index > 1 && index < activeIndex - 1 ) {
          addClassesToElement( $element, 'left-items' );
        }

        if ( index > activeIndex + 1 && index < lastIndex - 1 ) {
          addClassesToElement( $element, 'right-items' );
        }
      } );

      const leftHiddenContent = facetPagination.find( '.facetwp-page.hide-mobile.left-items' );
      const rightHiddenContent = facetPagination.find( '.facetwp-page.hide-mobile.right-items' );

      insertDotsContentIfNeeded( leftHiddenContent, dotsContent );
      insertDotsContentIfNeeded( rightHiddenContent, dotsContent );
    }
  };


  const hideFiltersWhenEmpty = () => {
    const allFacetOptions = $( '.facetwp-type-fselect .fs-options' );
    if ( allFacetOptions.length > 0 ) {
      allFacetOptions.each( ( i, e ) => {
        const element = $( e );
        if ( element.find( 'div' ).length === 0 ) {
          element.closest( '.bs-column' ).hide();
        } else {
          element.closest( '.bs-column' ).show();
        }
      } );
    }
  };

  const addWrapperWithImageClass = () => {
    const wrapperDiv = $( '.bs-posts .bs-posts__list' );
    if ( wrapperDiv.find( 'div.has-image' ).length > 0 ) {
      wrapperDiv.addClass( 'with-image' );
    } else {
      wrapperDiv.removeClass( 'with-image' );
    }
  };

  $( document ).on( 'facetwp-refresh', function () {
    if ( FWP.soft_refresh === true ) {
      FWP.enable_scroll = true;
    } else {
      FWP.enable_scroll = false;
    }
  } );

  $( document ).on( 'click', '.facetwp-page', ( e ) => {

    const adminBarHeight =
      $( '#wpadminbar' ).length > 0 ? $( '#wpadminbar' ).height() : 0;
    const headerHeight =
      $( 'header' ).height() + adminBarHeight;
    $( 'html, body' ).animate(
      { scrollTop: $( e.currentTarget ).closest( 'section' ).offset().top - headerHeight },
      1
    );
  } );

  $( document ).on( 'facetwp-refresh', () => {
    if ( null !== FWP.active_facet ) {
      let facet = FWP.active_facet;
      let facet_name = facet.attr( 'data-name' );
      $( '.facetwp-facet[data-name="'+ facet_name +'"]' ).addClass( 'current-loading-facetwp' );
    }
  } );

  $( document ).on( 'facetwp-refresh', () => {
    $( '.facetwp-template' ).addClass( 'facetwp-template--loading' );
  } );

  $( document ).on( 'facetwp-loaded', () => {
    addWrapperWithImageClass();
    mobilePaginationClassAdding();
    hideFiltersWhenEmpty();
    $( '.facetwp-template' ).removeClass( 'facetwp-template--loading' );
    $( '.facetwp-facet' ).removeClass( 'current-loading-facetwp' );
  } );

  $( window ).on( 'load resize', function ( e ) {
    addWrapperWithImageClass();
    mobilePaginationClassAdding();
    hideFiltersWhenEmpty();
  } );
} );
