$( ( $ ) => {
  // Add click event listener to tab links
  $( '.tab-nav a' ).on( 'click', ( e ) => {
    e.preventDefault();

    // Remove the active class from all tab links
    $( '.tab-nav a' ).removeClass( 'active' );

    // Add the active class to the clicked tab link
    $( e.currentTarget ).addClass( 'active' );

    // Hide all tab content
    $( '.tab-content' ).hide();

    // Show the corresponding tab content
    const tabId = $( e.currentTarget ).attr( 'href' );
    $( tabId ).fadeIn();
  } );

  // Initially, show the content of the first tab
  $( '.tab-nav a:first' ).click();
  $( '.tab-nav' ).parent().addClass( 'tab-initialized' );
} );
