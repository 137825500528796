// eslint-disable-next-line no-undef
( $ => {
  const footer = $( 'footer' );

  const footerNavMobile = () => {
    const $footer = $( 'footer' ),
      $footerMenu = $footer.find( '.menu-wrapper' ),
      $dropDowns = $footerMenu.find( '.navbar-nav > .menu-item-has-children' );

    $footer.addClass( 'accordion-active' );

    $dropDowns.each( ( index, element ) => {
      const menuItem = $( element );
      menuItem.find( '> a' ).on( 'click', e => {
        e.preventDefault();
        if ( !menuItem.hasClass( 'opened' ) ) {
          $dropDowns.find( '> .sub-menu' ).slideUp( 300 );
          $dropDowns.removeClass( 'opened' );
          menuItem.find( '.sub-menu' ).slideDown( 300 );
          menuItem.addClass( 'opened' );
        } else {
          menuItem.find( '> .sub-menu' ).slideUp( 300 );
          menuItem.removeClass( 'opened' );
        }
      } );
    } );
  };

  const mediaObject = window.matchMedia( '(max-width: 767px)' );

  if ( footer.length ) {
    mediaObject.addEventListener( 'change', () => {
      footerNavMobile();
    } );
  }
  footerNavMobile();
} )( jQuery );
