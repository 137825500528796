$( ( $ ) => {
  const toolTip = $( '.tool-tip' );
  if ( toolTip.length ) {
    toolTip.each( ( i, item ) => {
      const toolTipIcon = $( item ).find( '.icon' );
      const popup = $( item ).find( '.popup' );
      toolTipIcon.on( 'click', e => {
        e.preventDefault();
        if ( !popup.hasClass( 'opened' ) ) {
          $( '.popup' ).removeClass( 'opened' );
          $( popup ).addClass( 'opened' );
        } else {
          $( popup ).removeClass( 'opened' );
        }
      } );
    } );
  }
} );