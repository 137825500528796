$( ( $ ) => {
  const header = $( '.bs-section--home-banner, .bs-section--sticky-notification' ),
    /* eslint-disable-next-line max-len */
    footer = $( '.footer, .bs-section--home-cta-section, .bs-section--common-newsletter, .bs-section--common-pattern-extended' ),
    body = $( 'body' );

  const toggleClassOnBody = ( entries ) => {
    entries.forEach( ( entry ) => {
      if ( entry.isIntersecting ) {
        body.addClass( 'body-sticky' );
      } else {
        body.removeClass( 'body-sticky' );
      }
    } );
  };

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3, // Adjust this value to control when to toggle the class
  };

  const observer = new IntersectionObserver( toggleClassOnBody, observerOptions );
  if ( header.length ) {
    observer.observe( header[0] ); // Access the underlying DOM element with [0]
    observer.observe( footer[0] ); // Access the underlying DOM element with [0]
  }
} );