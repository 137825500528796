/* eslint-disable max-len */
import { CountUp } from 'countup.js';

$( () => {
  const slickSlide = $( '.bs-section--customer-counter-section .bs-slider--customers-what-our-customers-slider .slick-slider' );

  if ( slickSlide ) {

    slickSlide.on( 'afterChange', ( event, slick, currentSlide ) => {
      $( slick.$slides[currentSlide] )
        .find( '[data-counterup]' )
        .each( ( i, item ) => {
          const { endVal, ...options } = $( item ).data( 'options' );
          const countUp = new CountUp( item, endVal, options );

          if ( !countUp.error ) {
            countUp.start();
          }
        } );
    } );
  }
  slickSlide.slick();
    
} );
