$( () => {
  const mediaQuery = window.matchMedia( '(min-width: 992px)' );

  const handleBreakpointChange = ( mediaQuery ) => {

    // eslint-disable-next-line max-len
    const firstRowCardsHeight = $( '.bs-section--platform-overview-card-slider .bs-div--tos-highlighted' ).outerHeight();
    const cardItem = $( '.bs-section--platform-overview-card-slider .slick-slide-wrapper' ).first();

    if ( mediaQuery.matches ) {
      cardItem.find( '.bs-div' ).css( 'min-height', firstRowCardsHeight + 'px' );
    } else {
      cardItem.find( '.bs-div' ).css( 'min-height', 'auto' );
    }
  };

  const handleResizeLoad = () => {
    handleBreakpointChange( mediaQuery );
  };

  mediaQuery.addListener( handleBreakpointChange );

  $( window ).on( 'resize load', handleResizeLoad );
  
} );
